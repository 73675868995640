import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Includes } from './Includes'
import { Package, Props as PackageProps } from './Package'

export interface Props {
  beURL?: string
  contactsPageURL?: string
  includesDescription?: string
  includesTitle?: string
  languageCode: string
  packages?: PackageProps[]
}

export const OfferContent = memo(function OfferContent({
  beURL,
  contactsPageURL,
  includesDescription,
  includesTitle,
  languageCode,
  packages,
}: Props) {
  return (
    <Container>
      {includesDescription ? (
        <Includes description={includesDescription} title={includesTitle} />
      ) : null}

      {packages && packages?.length > 0 ? (
        <>
          <Title>
            <Line />
            {useVocabularyData('packages', languageCode)}
          </Title>
          <Packages>
            {packages.map((item, index) => (
              <Package key={index} {...item} />
            ))}
          </Packages>
        </>
      ) : null}

      {packages && packages?.length < 1 ? (
        <>
          <Buttons row>
            {beURL ? (
              <Button
                label={useVocabularyData('book-now', languageCode)}
                target="_blank"
                URL={beURL}
              />
            ) : null}
            {contactsPageURL ? (
              <Button
                label={useVocabularyData('request-info', languageCode)}
                target="_blank"
                URL={contactsPageURL}
                variant="outline"
              />
            ) : null}
          </Buttons>
        </>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-bottom: 7.5rem;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-bottom: 3.75rem;
    padding: 0 1.3125rem;
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 6.25rem;

  a {
    &:nth-of-type(2) {
      margin-left: 1rem;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin: 12px 0 0 0;
      }
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin: 6.25rem 0 4.0625rem;

  @media (max-width: 1023px) {
    margin: 5.3125rem 0 3.125rem;
  }
`

const Packages = styled.div``
