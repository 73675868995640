import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  beURL?: string
  image?: ImageProps
  languageCode: string
  price?: string
  contactsPageURL?: string
  sort?: number
  title?: string
}

export const Package = memo(function Package({
  beURL,
  image,
  languageCode,
  price,
  contactsPageURL,
  title,
}: Props) {
  return (
    <Container dial={4} row wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide>
        {title ? <Title>{title}</Title> : null}
        {price ? <Price>{price}</Price> : null}

        {beURL || contactsPageURL ? (
          <Buttons row>
            {beURL ? (
              <Button
                label={useVocabularyData('book-now', languageCode)}
                target="_blank"
                URL={beURL}
              />
            ) : null}
            {contactsPageURL ? (
              <Button
                label={useVocabularyData('request-info', languageCode)}
                target="_blank"
                URL={contactsPageURL}
                variant="outline"
              />
            ) : null}
          </Buttons>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.75rem;
  &:first-of-type {
    margin-top: 0;
  }
`

const LeftSide = styled.div`
  width: 29%;
  padding-bottom: 24%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-bottom: 80%;
  }
`

const RightSide = styled.div`
  padding-left: 3.958vw;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.875rem;
    padding-left: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  line-height: 2.125rem;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

const Price = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.125rem;
  margin-top: 0.375rem;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 1.875rem;

  a {
    &:nth-of-type(2) {
      margin-left: 1rem;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin: 12px 0 0 0;
      }
    }
  }
`
