import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  mobileImage?: ImageProps
}

export const SimpleHero = memo(function SimpleHero({
  image,
  mobileImage,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <>
      {mobileImage ? (
        <Media lessThan="ipadVertical">
          <Helmet>
            <link
              rel="preload"
              as="image"
              href={mobileImage.src}
              // @ts-ignore
              imageSrcSet={mobileImage.srcSet}
            />
          </Helmet>
        </Media>
      ) : null}

      <Container>
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadVertical">
            <Picture decoding="async" {...mobileImage} />
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            <Spinner variant="simple" />
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          </Media>
        </MediaContextProvider>
      </Container>
    </>
  )
})

const Container = styled.section`
  height: 66.7vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 1023px) {
    height: 50vh;
  }
`

const Picture = styled(Image)`
  width: 100%;
  height: calc(100% - 4.375rem);
  overflow: hidden;
  position: absolute;
  top: 4.375rem;
  left: 0;
`
