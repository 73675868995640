import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Includes = memo(function Includes({ description, title }: Props) {
  if (!description && !title) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 5rem;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 1.125rem;
  letter-spacing: 0.1125rem;
  line-height: 1.3125rem;
  margin-bottom: 1.5625rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1rem;
  line-height: 2.25rem;

  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 1.25rem;
      position: relative;
      &:before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark2};
        border-radius: 50%;
        position: absolute;
        top: 0.9375rem;
        left: 0;
      }
    }
  }
`
