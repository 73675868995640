import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  priceLabel?: string
  priceValue?: string
  subtitle?: string
  title: string
  validity?: string
}

export const OfferIntro = memo(function OfferIntro({
  description,
  priceLabel,
  priceValue,
  subtitle,
  title,
  validity,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      <LeftSide>
        <Title>{title}</Title>
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {validity ? <Validity>{validity}</Validity> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>

      {priceValue ? (
        <RightSide dial={5}>
          <Price>
            {priceLabel ? <PriceLabel>{priceLabel}</PriceLabel> : null}
            <PriceValue>{priceValue}</PriceValue>
          </Price>
        </RightSide>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 66.666%;
  padding: 5.375rem 10vw 0;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding-right: 1.3125rem;
    padding-left: 1.3125rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  margin-top: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.625rem;
  }
`

const Validity = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2.125rem;
  margin-top: 4rem;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    border-radius: 50%;
    margin-right: 0.75rem;
  }

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;
  margin-top: 1.875rem;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 2rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 33.333%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 9.125rem 1.875rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 2.5rem 1.3125rem;
  }
`

const Price = styled.div`
  @media (max-width: 1023px) {
    text-align: center;
  }
`

const PriceLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
`

const PriceValue = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.125rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`
